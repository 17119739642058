(function () {
  'use strict';

  class ReportsCtrl {
    constructor() {
      let vm = this;
      vm.ctrlName = 'ReportsCtrl';
    }
  }

  /**
   * @ngdoc object
   * @name dashboard.reports.controller:ReportsCtrl
   *
   * @description
   *
   */
  angular
    .module('dashboard.reports')
    .controller('ReportsCtrl', ReportsCtrl);
}());
